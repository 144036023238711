import firebase from 'firebase/compat/app';
import withoutNulls from '../api/search/withoutNulls';

const fmcsaSyncConfigConverter = {
  toFirestore(report: FMCSASyncConfig): firebase.firestore.DocumentData {
    return withoutNulls(report);
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<FMCSASyncConfig>,
    options: firebase.firestore.SnapshotOptions,
  ): FMCSASyncConfig {
    return snapshot.data(options);
  },
};

const getFmcsaSyncConfigRef = ({
  id,
  db,
}: {
  id: string;
  db?: firebase.firestore.Firestore;
}) => {
  return (db ? db : firebase.firestore())
    .collection('FMCSASyncConfig')
    .doc(id)
    .withConverter(fmcsaSyncConfigConverter);
};

export default getFmcsaSyncConfigRef;
