import React, { ReactElement } from 'react';
import Modal from '@atlaskit/modal-dialog';
import styled from 'styled-components';

import Row from '../Common/Row';
import Button from 'kingpin/atoms/Button';
import Icon from './Icon';
import Typography from 'kingpin/atoms/Typography';
import Colors2 from '../../theme/Colors2';
import WarningButton from '../WarningButton';

const MainDiv = styled.div`
  background-color: white;
`;
const BodyDiv = styled.div`
  padding: 40px 32px 24px 32px;
  border-bottom: solid 1px ${Colors2.Primary['8']};

  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BottomBarDiv = styled.div`
  padding: 16px 32px;
`;

const WarningConfirmationModal = ({
  title,
  body,
  isOpen,
  close,
  onConfirmed,
  continueText,
}: {
  title: string;
  body: string | ReactElement;
  isOpen: boolean;
  close: () => void;
  onConfirmed: () => void;
  continueText?: string;
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      onClose={close}
      shouldScrollInViewport={false}
      autoFocus={false}
      width={448}
    >
      <MainDiv>
        <BodyDiv>
          <Icon type={'warning'} />
          <div style={{ marginBottom: 12 }}>
            <Typography.Header type="H4">{title}</Typography.Header>
          </div>
          <div style={{ marginBottom: 24 }}>
            <Typography.Body type="Body 12">{body}</Typography.Body>
          </div>
        </BodyDiv>
        <BottomBarDiv>
          <Row spaceBetween centerAlign>
            <Button
              type="Tertiary"
              size="Small"
              onClick={close}
              label="Cancel"
            />
            <WarningButton
              testId="confirm-warning"
              onClick={onConfirmed}
              text={continueText ? continueText : 'Continue'}
            />
          </Row>
        </BottomBarDiv>
      </MainDiv>
    </Modal>
  );
};

export default WarningConfirmationModal;
